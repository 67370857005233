import * as React from "react";
import { ModuleMapProps } from "./../typings";
import { useFullscreenOpen } from ".././hooks";
import { UitkSecondaryButton } from "@egds/react-core/button";
import { UitkIcon } from "@egds/react-core/icons";
import { useLocalization } from "@shared-ui/localization-context";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { isVariantEnabled } from "components/utility/experiment";

/**
 * Simple view for a map component that displays as a button that sends the user to SRP (opens the map view in SRP)
 */
const MapButton = withStores(
  "flexModuleModelStore",
  "analytics",
  "hotels",
  "staticMap",
  "context",
  "flightsStore",
  "compositionStore"
)(
  observer((props: ModuleMapProps) => {
    if (!props.templateComponent) {
      return null;
    }

    const { sendUserToSRP } = useFullscreenOpen(props);
    const { formatText } = useLocalization();

    const ctaText = isVariantEnabled(props.context, "Blossom_SRP_Lite_V2")
      ? formatText("maps.callToActionButtonViewInAMap")
      : formatText("maps.callToActionButtonShowMap");

    return (
      <div>
        <UitkSecondaryButton isFullWidth onClick={sendUserToSRP}>
          <UitkIcon name="map" />
          {ctaText}
        </UitkSecondaryButton>
      </div>
    );
  })
);

export default MapButton;
